import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import LegalLayout from "../components/LegalLayout/LegalLayout";

function TermsAndConditions() {
	const data = useStaticQuery(query);
	const { tnc_title: title, tnc_body: body } = data?.file?.childMarkdownRemark?.frontmatter || {};

	return <LegalLayout data={{ title, body }} />;
}

export default TermsAndConditions;

const query = graphql`
	{
		file(name: { eq: "terms_and_conditions" }) {
			childMarkdownRemark {
				frontmatter {
					tnc_title
					tnc_body
				}
			}
		}
	}
`;
